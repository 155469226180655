import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/login'
import Layout from './pages/Layout/Layout'

const App: React.FC<any> = () => {
	// useEffect(() => { // 禁止F12打开控制台
	// 	document.addEventListener('keydown', function(event){
	// 		return 123 !== event.keyCode || (event.returnValue = false)
	// 	});
	// }, [])
	return (
		<Router>
			<div className="App">
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/" component={Layout} />
				</Switch>
			</div>
		</Router>
	)
}

export default App;