import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import PrivateRoute from '../../components/privateRoute/Index'
import { Layout, Menu, Dropdown, message,Spin } from 'antd';
import { getFileUrl,logoutEvent } from '../../fetch/api'
import avatar from '../../images/logo.png'
import './Layout.css';


const { Header, Sider, Content } = Layout;

const Layout_: React.FC<any> = () => {
	const history = useHistory()
	const [loadingMain,setLoadingMain] = useState<boolean>(false)
	const [userInfo, setUserInfo] = useState<any>(null)
	const [userAvatarUrl, setUserAvatarUrl] = useState<any>(null)
	useEffect(() => {
		if (localStorage.userInfo) {
			let userInfo = JSON.parse(localStorage.userInfo)
			if (userInfo.portrait) downloadFile(userInfo.portrait)
			setUserInfo(userInfo)
		}
	}, [])
	const clickMenu = (param: any) => {
		switch (param.key) {
			case 'logout':
				setLoadingMain(true)
				logoutEvent({}).then((res: any) => {
					setLoadingMain(false)
					if (res.code === 200) {
						localStorage.removeItem('Authorization')
						history.push('/login')
					} else {
						message.error(res.message)
					}
				}).catch(() => setLoadingMain(false))
				break;
		}
	}
	/**获取上传后的文件url */
	const downloadFile = (path: any) => {
		getFileUrl({ path: path }).then((res: any) => { // 先获取文件网络地址
			if (res.code === 200) {
				if (res.data) {
					setUserAvatarUrl(res.data)
				}
			}
		})
	}

	const menu = (
		<Menu onClick={clickMenu} className="tx_c">
			{/* <Menu.Item key="personal">个人中心</Menu.Item> */}
			<Menu.Item key="logout">退出登录</Menu.Item>
		</Menu>
	)



	return (
		<Layout>
			
			<Layout className="site-layout">
				<Header className="bgBlue flex_just_betw flex_cen" style={{ padding: '0 15px' }}>
					<div className="flex_just_betw flex_cen">
						<div className="wlecomText">欢迎使用蓝鲸鱼业务系统~</div>
					</div>
					<div className="welecome_right">
						
						<div className="username">{userInfo ? userInfo.name : '--'}</div>
						<Dropdown overlay={menu} placement="bottomRight" arrow>
							<div className="downBox">
								<div className="userPhoto">
									<img src={userAvatarUrl ? userAvatarUrl : avatar} alt="" />
								</div>
								<div className="dowmArror iconfont iconicon_zhan_kai"></div>
							</div>
						</Dropdown>
					</div>
				</Header>
				<Content
					style={{
						margin: '15px',
						padding: 10,
						minHeight: 280,
					}}
				>
					{loadingMain && <div className="loadingStyle"><Spin tip="加载中..." /></div>}
					<PrivateRoute />
				</Content>
			</Layout>
		</Layout>
	)
}

export default Layout_