import { fetch } from './axios'

/**客户服务  */
const serviceKeyWord_custom: string = '/business'
// const serviceKeyWord_custom: string = '/lxz-business'  
// const serviceKeyWord_custom: string = '/lk-business'  
// const serviceKeyWord_custom: string = '/haven-business'
// const serviceKeyWord_custom: string = 'jiang-business'
// const serviceKeyWord_custom:string = '/lidong-business'
// const serviceKeyWord_custom:string = '/cheng-business'
// const serviceKeyWord_custom:string = '/xz-business'


// const serviceKeyWord_custom:string = '/zcb-business'

/**产品  */
const serviceKeyWord_product: string = '/product-open'

/**权限服务  */

const serviceKeyWord_permissions: string = '/permissions'

/**认证服务 */
const serviceKeyWord_auth: string = '/auth'

/**文件服务 */
const serviceKeyWord_oss: string = '/oss'

/**订单服务  */
const serviceKeyWord_signOrder: string = '/loanOrder'
// const serviceKeyWord_signOrder:string = '/zcb-order'

/**配置服务 */
const serviceKeyWord_config: string = '/config'
// const serviceKeyWord_config:string = '/zcb-config' 

/**消息服务 */
const serviceKeyWord_im: string = '/im'

/**短信服务 */
const serviceKeyWord_sms: string = '/sms-business'
// const serviceKeyWord_sms: string = '/lidong-sms'

/**流程服务 */
const serviceKeyWord_flow: string = '/flow'

/**财务服务 */
const serviceKeyWord_finance: string = '/finance'
// const serviceKeyWord_finance: string = '/cheng-finance' 
// const serviceKeyWord_flow: string = '/zcb-flow' 
// const serviceKeyWord_flow: string = '/lidong-flow' 


const serviceKeyWord_baseFrame: string = '/quality-inspection' // 智能质检服务
// const serviceKeyWord_baseFrame: string = '/lxz-quality-inspection' // 智能质检服务
const serviceKeyWord_elecContract: string = '/electronic-contract' // 电子合同服务

export const logoutEvent: any = (data: any) => { // 退出登录
  return fetch(`/user/logout/web`, data, `post`)
}
export const login: any = (data: any) => { // 登录
  return fetch(`/user/login/web`, data, `post`)
}
export const getPublicAccessPrefix: any = (data: any) => { // 获取公有附件访问前缀
  return fetch(`${serviceKeyWord_oss}/oss/getPublicAccessPrefix`, data, `get`)
}
export const getSubcompanyLogo: any = (data: any) => { // 获取子公司logo
  return fetch(`${serviceKeyWord_config}/company/getSubcompanyLogo`, data, 'post')
}
/**权限 */
export const getRoleMenuByAppId: any = (data: any) => { // 获取角色在应用下拥有的菜单权限（万春）
  return fetch(`${serviceKeyWord_auth}/menu/getRoleMenuByAppId`, data, `get`)
}
export const getAppsByRole: any = (data: any) => { // 获取角色拥有的系统
  return fetch(`${serviceKeyWord_auth}/sysApp/getAppsByRole`, data, `get`)
}
export const getAppId: any = (data: any) => { // 获取平台在企业微信的appid
  return fetch(`/authOpen/provider/getAppId`, data, 'get')
}
export const weChatLogin: any = (data: any) => { // 微信扫码登录
  return fetch(`/weChat/login`, data, 'post')
}
export const getFileUrl: any = (data: any) => { // 获取文件访问地址
  return fetch(`${serviceKeyWord_oss}/oss/getFile`, data, `get`)
}
export const modifyUserPwd: any = (data: any) => { //修改用户密码
  return fetch(`${serviceKeyWord_auth}/user/modifyUserPwd`, data, `post`)
}
