import axios from 'axios'
import qs from 'qs';
import { message } from 'antd';
import { signEvent } from './JWT'

export const suite_id = 'ww4e863a5e0573087f' //企业微信授权suite_id
// export const baseURL = process.env.NODE_ENV === "testbuild" ? 'http://route.test.com:7000' :
// 	process.env.NODE_ENV === "uatbuild" ? 'http://10.10.10.202:6031' : 
// 	process.env.NODE_ENV === "prodbuild" ? '' : 'http://route.test.com:7000'
export const baseURL = process.env.NODE_ENV === "testbuild" ? 'https://testgw.kuanjie.work' :
	process.env.NODE_ENV === "uatbuild" ? 'https://uatgateway.bluejingyu.com' :
	process.env.NODE_ENV === "prodbuild" ? 'https://gateway.kuanjie.cloud' : 'https://testgw.kuanjie.work'


const Axios = axios.create({
	baseURL: baseURL,
	timeout: 15000 // 请求超时时间  
})

let pending: any[] = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = (config: { url: string; method: string; }) => {
	for (let p in pending) {
		if (pending[p].u === config.url + '&' + config.method) { //当当前请求在数组中存在时执行函数体
			pending[p].f(); //执行取消操作
			pending.splice(Number(p), 1); //把这条记录从数组中移除
		}
	}
}
// 添加请求拦截器
Axios.interceptors.request.use((config: any) => {
	// 在发送请求之前做些什么
	console.log(config)
	config.headers['Authorization'] = localStorage.Authorization
	config.headers['sign'] = signEvent(config.baseURL + config.url) //jwt（JSON Web Token）加密
	if (!config.url.includes('oss/uploadFile')) {
		removePending(config); //在一个ajax发送前执行一下取消操作
		config.cancelToken = new cancelToken((c) => {
			// 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
			pending.push({ u: config.url + '&' + config.method, f: c });
		});
	}
	return config;
}, (error: any) => {
	// 对请求错误做些什么
	return Promise.reject(error)
})

// 添加响应拦截器
Axios.interceptors.response.use((response: any) => {
	// 对响应数据做点什么
	removePending(response.config); // 在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
	if (response.data.code === 401) {
		message.error(response.data.message)
		setTimeout(() => {
			window.location.href = '/login'
		}, 1e3);
	} else {
		return response.data
	}
	return response.data
}, (error: any) => {
	if (error.response?.status === 401) {
		message.error(error.response.data.message)
		setTimeout(() => {
			window.location.href = '/login'
		}, 1e3);
	}
	return Promise.reject(error)
})

// 发起请求
export const fetch = (url: string, data: any, method: string = `GET`, contentType: string = 'json') => {
	data = data || ''
	if (method === `GET` || method === `get`) {
		return Axios.get(url, data.params ? data : {
			params: data
		})
	}
	if (method === 'DELETE' || method === 'delete') {
		// return Axios.delete(url, data.params ? data : { // header传参
		//   params: data
		// })
		return Axios.delete(url, data.data ? data : { // body传参
			data: data
		})
	}
	if (method === 'POST' || method === 'post') {
		if (contentType === 'form') {
			return Axios.post(url, qs.stringify(data))
		} else {
			return Axios.post(url, data)
		}
	}
	if (method === 'PATCH' || method === 'patch') {
		return Axios.post(url, data)
	}
	if (method === 'PUT' || method === 'put') {
		return Axios.post(url, data)
	}
}
